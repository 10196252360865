/**
 * @generated SignedSource<<9d8fdb52901dff80f4dbe25fbf96b4e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RSVPToAllOrganizationEventsButtonQuery$variables = {
  excludeCommunityEvents?: boolean | null;
  excludeProductEvents?: boolean | null;
  id: string;
  includedProductIds?: ReadonlyArray<string> | null;
};
export type RSVPToAllOrganizationEventsButtonQuery$data = {
  readonly organization: {
    readonly id?: string;
    readonly occurrences?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
      readonly totalCount: number;
    };
  } | null;
};
export type RSVPToAllOrganizationEventsButtonQuery = {
  response: RSVPToAllOrganizationEventsButtonQuery$data;
  variables: RSVPToAllOrganizationEventsButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeCommunityEvents"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeProductEvents"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includedProductIds"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "atCapacity",
  "value": false
},
v7 = {
  "kind": "Literal",
  "name": "datetimeFilter",
  "value": "upcoming"
},
v8 = {
  "kind": "Variable",
  "name": "excludeCommunityEvents",
  "variableName": "excludeCommunityEvents"
},
v9 = {
  "kind": "Variable",
  "name": "excludeProductEvents",
  "variableName": "excludeProductEvents"
},
v10 = {
  "kind": "Variable",
  "name": "includedProductIds",
  "variableName": "includedProductIds"
},
v11 = {
  "kind": "Literal",
  "name": "productStatus",
  "value": "published"
},
v12 = {
  "kind": "Literal",
  "name": "requireProductMembership",
  "value": true
},
v13 = {
  "kind": "Literal",
  "name": "status",
  "value": "published"
},
v14 = {
  "kind": "Literal",
  "name": "viewerHasRSVP",
  "value": false
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OccurrenceNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Occurrence",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoObjectType",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v17 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RSVPToAllOrganizationEventsButtonQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "alias": "occurrences",
                "args": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "__RSVPToAllOrganizationEventsButton_occurrences_connection",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "RSVPToAllOrganizationEventsButtonQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "OccurrenceNodeConnection",
                "kind": "LinkedField",
                "name": "occurrences",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "filters": [
                  "viewerHasRSVP",
                  "atCapacity",
                  "datetimeFilter",
                  "status",
                  "productStatus",
                  "requireProductMembership",
                  "excludeProductEvents",
                  "excludeCommunityEvents",
                  "includedProductIds"
                ],
                "handle": "connection",
                "key": "RSVPToAllOrganizationEventsButton_occurrences",
                "kind": "LinkedHandle",
                "name": "occurrences"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52a071f19a03606302e0126559693094",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "organization",
            "occurrences"
          ]
        }
      ]
    },
    "name": "RSVPToAllOrganizationEventsButtonQuery",
    "operationKind": "query",
    "text": "query RSVPToAllOrganizationEventsButtonQuery(\n  $id: ID!\n  $excludeProductEvents: Boolean\n  $excludeCommunityEvents: Boolean\n  $includedProductIds: [ID!]\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      id\n      occurrences(first: 1, viewerHasRSVP: false, atCapacity: false, datetimeFilter: upcoming, status: published, productStatus: published, requireProductMembership: true, excludeProductEvents: $excludeProductEvents, excludeCommunityEvents: $excludeCommunityEvents, includedProductIds: $includedProductIds) {\n        totalCount\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "aaaeaeb0bd429040e19b56aad42bbdf0";

export default node;
