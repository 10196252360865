/**
 * @generated SignedSource<<07ce806f6a39ee5a8c399e52bf1016e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type LabelKind = "admin_experience" | "admin_member" | "assignment" | "curriculum" | "custom" | "experience" | "lesson" | "organization_admin" | "organization_member" | "organization_owner" | "pathway" | "product_admin" | "product_instructor" | "product_member" | "quiz" | "sidebar_community" | "sidebar_product" | "%future added value";
export type OrganizationCheckoutVersion = "stripe" | "stripe_acacia" | "%future added value";
export type OrganizationCurrency = "aed" | "afn" | "all" | "amd" | "ang" | "aoa" | "ars" | "aud" | "awg" | "azn" | "bam" | "bbd" | "bdt" | "bgn" | "bmd" | "bnd" | "bob" | "brl" | "bsd" | "bwp" | "byn" | "bzd" | "cad" | "chf" | "cny" | "cop" | "crc" | "cve" | "czk" | "dkk" | "dop" | "dzd" | "egp" | "etb" | "eur" | "fjd" | "fkp" | "gbp" | "gel" | "gip" | "gmd" | "hkd" | "hnl" | "htg" | "huf" | "idr" | "ils" | "inr" | "isk" | "jmd" | "kgs" | "lak" | "lbp" | "lkr" | "lrd" | "lsl" | "mad" | "mdl" | "mkd" | "mmk" | "mnt" | "mop" | "mur" | "mvr" | "mxn" | "myr" | "mzn" | "nad" | "ngn" | "nio" | "nok" | "npr" | "nzd" | "pab" | "pen" | "pgk" | "php" | "pkr" | "pln" | "qar" | "ron" | "rsd" | "rub" | "sar" | "sbd" | "scr" | "sek" | "sgd" | "shp" | "srd" | "std" | "szl" | "thb" | "tjs" | "top" | "try" | "ttd" | "twd" | "uah" | "usd" | "uyu" | "uzs" | "zar" | "zmw" | "%future added value";
export type OrganizationVisibility = "private" | "public" | "%future added value";
export type SearchOptOutEntity = "chat_channel" | "chat_message" | "content_usage" | "feed" | "occurrence" | "post" | "product" | "user" | "%future added value";
export type SearchOptOutRole = "admin" | "member" | "owner" | "%future added value";
export type ThemeKind = "custom" | "dark" | "light" | "%future added value";
export type UpdateOrganizationSettingsInput = {
  badge?: BadgeInput | null;
  billingContactEmail?: string | null;
  checkoutVersion?: OrganizationCheckoutVersion | null;
  cover?: string | null;
  coverAssetId?: string | null;
  currency?: OrganizationCurrency | null;
  darkLogo?: string | null;
  darkLogoAssetId?: string | null;
  description?: string | null;
  discoCompanyDomain?: string | null;
  experienceNavOrdering?: ReadonlyArray<string> | null;
  exploreProductTabSortBySetting?: string | null;
  faviconUrl?: string | null;
  id: string;
  isAskAIEnabled?: boolean | null;
  isChannelsEnabled?: boolean | null;
  isDmEnabled?: boolean | null;
  isEventsEnabled?: boolean | null;
  isExploreProductTabHidden?: boolean | null;
  isProductsEnabled?: boolean | null;
  label?: OrganizationSettingsLabelInput | null;
  logo?: string | null;
  logoAssetId?: string | null;
  name?: string | null;
  searchOptOuts?: ReadonlyArray<SearchOptOutInput> | null;
  showGlobalTabTitles?: boolean | null;
  slug?: string | null;
  theme?: OrganizationSettingsThemeInput | null;
  tracking?: UpdateTrackingInput | null;
  visibility?: OrganizationVisibility | null;
};
export type UpdateTrackingInput = {
  facebookPixelId?: string | null;
  googleAnalyticsId?: string | null;
  googleTagManagerId?: string | null;
};
export type BadgeInput = {
  assetId?: string | null;
  color?: string | null;
  emoji?: string | null;
  icon?: string | null;
  kind: BadgeKind;
  mediaUrl?: string | null;
};
export type OrganizationSettingsThemeInput = {
  backgroundColor?: string | null;
  cardBackgroundColor?: string | null;
  kind?: ThemeKind | null;
  navBackgroundColor?: string | null;
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
};
export type OrganizationSettingsLabelInput = {
  labels: ReadonlyArray<UpdateLabelInput>;
};
export type UpdateLabelInput = {
  description?: string | null;
  kind: LabelKind;
  plural: string;
  singular: string;
};
export type SearchOptOutInput = {
  entity: SearchOptOutEntity;
  role: SearchOptOutRole;
};
export type EditOrganizationGeneralSettingsFormMutation$variables = {
  input: UpdateOrganizationSettingsInput;
};
export type EditOrganizationGeneralSettingsFormMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly attribute: {
        readonly id: string;
        readonly name: string;
        readonly value: string;
      } | null;
      readonly billingContactEmail: string | null;
      readonly currency: OrganizationCurrency;
      readonly description: string | null;
      readonly id: string;
      readonly isChannelsEnabled: boolean;
      readonly isDmEnabled: boolean;
      readonly isEventsEnabled: boolean;
      readonly isProductsEnabled: boolean;
      readonly name: string;
      readonly primaryDomain: string;
      readonly searchOptOuts: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entity: SearchOptOutEntity;
            readonly id: string;
            readonly role: SearchOptOutRole;
          };
        }>;
      };
      readonly slug: string;
      readonly visibility: OrganizationVisibility;
      readonly " $fragmentSpreads": FragmentRefs<"useSearchClientFragment">;
    } | null;
  };
};
export type EditOrganizationGeneralSettingsFormMutation = {
  response: EditOrganizationGeneralSettingsFormMutation$data;
  variables: EditOrganizationGeneralSettingsFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDmEnabled",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isChannelsEnabled",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProductsEnabled",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEventsEnabled",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingContactEmail",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryDomain",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "disco_company_domain"
    }
  ],
  "concreteType": "OrganizationAttribute",
  "kind": "LinkedField",
  "name": "attribute",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": "attribute(name:\"disco_company_domain\")"
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "SearchOptOutNodeConnection",
  "kind": "LinkedField",
  "name": "searchOptOuts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchOptOutNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchOptOut",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditOrganizationGeneralSettingsFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsMutationResponse",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useSearchClientFragment"
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditOrganizationGeneralSettingsFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsMutationResponse",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "searchConfig",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "viewerMembership",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80ba6a5d02942af3a7b77438711efbb6",
    "id": null,
    "metadata": {},
    "name": "EditOrganizationGeneralSettingsFormMutation",
    "operationKind": "mutation",
    "text": "mutation EditOrganizationGeneralSettingsFormMutation(\n  $input: UpdateOrganizationSettingsInput!\n) {\n  response: updateOrganizationSettings(input: $input) {\n    node {\n      id\n      isDmEnabled\n      isChannelsEnabled\n      isProductsEnabled\n      isEventsEnabled\n      slug\n      description\n      name\n      billingContactEmail\n      currency\n      visibility\n      primaryDomain\n      attribute(name: \"disco_company_domain\") {\n        name\n        value\n        id\n      }\n      searchOptOuts {\n        edges {\n          node {\n            id\n            role\n            entity\n          }\n        }\n      }\n      ...useSearchClientFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment useSearchClientFragment on Organization {\n  id\n  searchConfig\n  viewerMembership {\n    searchOptOuts {\n      edges {\n        node {\n          id\n          role\n          entity\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0484e651c64e511ba16f0fbe46c0756e";

export default node;
