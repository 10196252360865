/**
 * @generated SignedSource<<c3bb165e77ba89b170dde869ef32fd62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationRole = "admin" | "member" | "owner" | "%future added value";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
export type OrganizationMemberEmailMultiSelectQuery$variables = {
  canReadPrivate: boolean;
  excludeProductIds?: ReadonlyArray<string> | null;
  excludeProductRoles?: ReadonlyArray<ProductRole> | null;
  excludeTestMembers: boolean;
  id: string;
  roles?: ReadonlyArray<OrganizationRole> | null;
};
export type OrganizationMemberEmailMultiSelectQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly organizationMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly email?: string | null;
          readonly id: string;
          readonly member: {
            readonly fullName: string;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemFragment">;
          };
          readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemPrivateFragment">;
        };
      }>;
    };
  } | null;
};
export type OrganizationMemberEmailMultiSelectQuery = {
  response: OrganizationMemberEmailMultiSelectQuery$data;
  variables: OrganizationMemberEmailMultiSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "canReadPrivate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeProductIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeProductRoles"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeTestMembers"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roles"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "excludeProductIds",
    "variableName": "excludeProductIds"
  },
  {
    "kind": "Variable",
    "name": "excludeProductRoles",
    "variableName": "excludeProductRoles"
  },
  {
    "kind": "Variable",
    "name": "excludeTestMembers",
    "variableName": "excludeTestMembers"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "recently_active"
  },
  {
    "kind": "Variable",
    "name": "roles",
    "variableName": "roles"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationMemberEmailMultiSelectQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "condition": "canReadPrivate",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "UserDropdownItemPrivateFragment"
                              },
                              (v9/*: any*/)
                            ]
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v10/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "UserDropdownItemFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationMemberEmailMultiSelectQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "condition": "canReadPrivate",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isPrivateUser"
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2274989a73cd97af037423eeb99ca83f",
    "id": null,
    "metadata": {},
    "name": "OrganizationMemberEmailMultiSelectQuery",
    "operationKind": "query",
    "text": "query OrganizationMemberEmailMultiSelectQuery(\n  $id: ID!\n  $roles: [OrganizationRole!]\n  $canReadPrivate: Boolean!\n  $excludeProductIds: [ID!]\n  $excludeProductRoles: [ProductRole!]\n  $excludeTestMembers: Boolean!\n) {\n  organization: node(id: $id) {\n    __typename\n    id\n    ... on Organization {\n      organizationMemberships(orderBy: recently_active, roles: $roles, excludeProductIds: $excludeProductIds, excludeProductRoles: $excludeProductRoles, excludeTestMembers: $excludeTestMembers) {\n        edges {\n          node {\n            id\n            ...UserDropdownItemPrivateFragment @include(if: $canReadPrivate)\n            email @include(if: $canReadPrivate)\n            member {\n              id\n              fullName\n              ...UserDropdownItemFragment\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment UserDropdownItemFragment on User {\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment UserDropdownItemPrivateFragment on PrivateUser {\n  __isPrivateUser: __typename\n  email\n}\n"
  }
};
})();

(node as any).hash = "0f6397fd39509a5072e74bed9ab0ae4a";

export default node;
