import { useLabel } from "@/core/context/LabelsContext"
import { DeleteNavSectionButtonFragment$key } from "@/organization/common/sidebar/nav-section/__generated__/DeleteNavSectionButtonFragment.graphql"
import { DeleteNavSectionButtonMutation } from "@/organization/common/sidebar/nav-section/__generated__/DeleteNavSectionButtonMutation.graphql"
import ProductUtils from "@/product/util/productUtils"
import Relay from "@/relay/relayUtils"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { useFragment, useMutation } from "react-relay"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"

type Props = OverridableDiscoButtonProps & {
  navSectionKey: DeleteNavSectionButtonFragment$key
}

function DeleteNavSectionButton({ children, navSectionKey, ...props }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const productLabel = useLabel("admin_experience")
  const invalidateProductsSidebar = ProductUtils.useInvalidateSidebar()

  const navSection = useFragment<DeleteNavSectionButtonFragment$key>(
    graphql`
      fragment DeleteNavSectionButtonFragment on NavSection {
        id
        title
        kind
        ordering
        organizationId
      }
    `,
    navSectionKey
  )

  const [commit, isDeleting] = useMutation<DeleteNavSectionButtonMutation>(
    graphql`
      mutation DeleteNavSectionButtonMutation($id: ID!, $isProducts: Boolean!) {
        response: deleteNavSection(id: $id) {
          deletedNavSectionId @deleteRecord
          organization @skip(if: $isProducts) {
            ...AppsSidebarList_OrganizationFragment
          }
          errors {
            field
            message
          }
        }
      }
    `
  )
  const childrenLabel = navSection.kind === "products" ? productLabel.plural : "apps"

  return (
    <>
      <OverridableDiscoButton
        {...props}
        testid={"DeleteNavSectionButton"}
        onClick={() => setIsOpen(true)}
      >
        {children}
      </OverridableDiscoButton>

      <DiscoWarningModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testid={"DeleteNavSectionModal"}
        title={"Delete this section?"}
        modalContentLabel={"Delete this section?"}
        description={`
          Are you sure you want to permanently delete the '${navSection.title}' section?
          Any ${childrenLabel} within this section will be moved into the top level.
        `}
        confirmationButtonProps={{
          onClick: handleSubmit,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete it",
        }}
      />
    </>
  )

  function handleSubmit() {
    commit({
      variables: {
        id: navSection.id,
        isProducts: navSection.kind === "products",
      },
      onCompleted({ response }) {
        if (response.errors?.length) return displayErrorToast(response.errors[0].message)

        displayToast({
          message: "Section removed!",
          testid: "DeleteNavSectionButton.success-toast",
        })
      },
      onError(error) {
        console.error(error)
        displayErrorToast(error)
      },
      updater(store, data) {
        // Delete the now empty edge from the sidebar connection
        const { deletedNavSectionId } = data.response
        if (!deletedNavSectionId) return
        const orgRecord = store.get(navSection.organizationId)
        if (!orgRecord) return
        ConnectionHandlerPlus.getConnections(
          orgRecord,
          navSection.kind === "products"
            ? "ProductsSidebar__productsNavSections"
            : "CommunitySideBar__communityNavSections"
        ).forEach((connection) =>
          Relay.deleteNodeFromConnection(connection, deletedNavSectionId)
        )

        // If this is a products section, invalidate the paginated sidebar instead of
        // querying everything in the response
        if (navSection.kind === "products") {
          invalidateProductsSidebar(store)
        }
      },
    })
  }
}

export default DeleteNavSectionButton
