import DeleteOccurrenceModal from "@/occurrence/button/delete-button/DeleteOccurrenceModal"
import { DeleteOccurrenceButtonFragment$key } from "@/occurrence/button/delete-button/__generated__/DeleteOccurrenceButtonFragment.graphql"
import { DeleteOccurrenceButtonModalPreloadedQuery } from "@/occurrence/button/delete-button/__generated__/DeleteOccurrenceButtonModalPreloadedQuery.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import useDisclosure from "@utils/hook/useDisclosure"
import { useFragment, useQueryLoader } from "react-relay"
import { graphql } from "relay-runtime"

interface DeleteOccurrenceButtonProps {
  occurrenceKey: DeleteOccurrenceButtonFragment$key
  children: OverridableDiscoButtonChildren
  onDelete?: () => void
}

export const DeleteOccurrenceButtonPreloadedQuery = graphql`
  query DeleteOccurrenceButtonModalPreloadedQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Occurrence {
        ...DeleteOccurrenceModalFragment @relay(mask: false)
      }
    }
  }
`

function DeleteOccurrenceButton({
  occurrenceKey,
  children,
  onDelete,
}: DeleteOccurrenceButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [queryReference, loadQuery] =
    useQueryLoader<DeleteOccurrenceButtonModalPreloadedQuery>(
      DeleteOccurrenceButtonPreloadedQuery
    )

  const occurrence = useFragment<DeleteOccurrenceButtonFragment$key>(
    graphql`
      fragment DeleteOccurrenceButtonFragment on Occurrence {
        id
      }
    `,
    occurrenceKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={onClick}>{children}</OverridableDiscoButton>
      {queryReference && (
        <DeleteOccurrenceModal
          isOpen={isOpen}
          onClose={onClose}
          onDelete={onDelete}
          occurrenceQueryRef={queryReference}
        />
      )}
    </>
  )

  // Load the preloaded query when the user clicks on the button
  // Ignore if the query has already been loaded
  function loadPreloadedQuery() {
    if (!queryReference) {
      loadQuery(
        { id: occurrence.id },
        {
          fetchPolicy: "network-only",
        }
      )
    }
  }

  function onClick() {
    loadPreloadedQuery()
    onOpen()
  }
}

export default Relay.withSkeleton({
  component: DeleteOccurrenceButton,
  skeleton: () => <DiscoButtonSkeleton />,
})
