import Badge from "@/admin/experiences/badges/Badge"
import AppsSidebarDragDropProvider, {
  AppsSidebarNavSectionsDragDrop,
} from "@/apps/sidebar-item/AppsSidebarDragDropProvider"
import AppsSidebarList from "@/apps/sidebar-item/AppsSidebarList"
import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import { AppLevelProvider } from "@/apps/util/appLevelContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CommunitySidebarHeader from "@/organization/common/sidebar/CommunitySidebarHeader"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import { CommunitySideBarPaginationQuery } from "@/organization/common/sidebar/__generated__/CommunitySideBarPaginationQuery.graphql"
import { CommunitySideBarQuery } from "@/organization/common/sidebar/__generated__/CommunitySideBarQuery.graphql"
import { CommunitySideBar_PaginationFragment$key } from "@/organization/common/sidebar/__generated__/CommunitySideBar_PaginationFragment.graphql"
import CreateNavSectionButton from "@/organization/common/sidebar/nav-section/CreateNavSectionButton"
import NavSection, {
  NavSectionSkeleton,
} from "@/organization/common/sidebar/nav-section/NavSection"
import Relay from "@/relay/relayUtils"
import {
  DiscoChip,
  DiscoDivider,
  DiscoSideBar,
  DiscoSideBarItemSkeleton,
  DiscoSideBarProps,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoDividerButton from "@disco-ui/button/DiscoDividerButton"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import DiscoSidebarHeader, {
  DiscoSidebarHeaderProps,
} from "@disco-ui/sidebar/DiscoSidebarHeader"
import { range } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { Fragment } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const COMMUNITY_SIDE_BAR_TEST_ID = "CommunitySideBar"
const NAV_SECTIONS_PER_PAGE = 15

function CommunitySideBar(props: DiscoSideBarProps) {
  const activeOrganization = useActiveOrganization()!

  const canManage = activeOrganization.viewerPermissions.has("dashboard.manage")
  const { organization } = useLazyLoadQuery<CommunitySideBarQuery>(
    graphql`
      query CommunitySideBarQuery($id: ID!, $first: Int!, $after: String) {
        organization: node(id: $id) {
          ... on Organization {
            ...AppsSidebarList_OrganizationFragment
            ...CommunitySideBar_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    { id: activeOrganization.id, first: NAV_SECTIONS_PER_PAGE }
  )

  // Use a separate fragment for ease of refetching the data in mutation responses
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    CommunitySideBarPaginationQuery,
    CommunitySideBar_PaginationFragment$key
  >(
    graphql`
      fragment CommunitySideBar_PaginationFragment on Organization
      @refetchable(queryName: "CommunitySideBarPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        communityNavSections: navSections(kind: custom, first: $first, after: $after)
          @connection(key: "CommunitySideBar__communityNavSections") {
          edges {
            node {
              id
              ...NavSectionFragment
            }
          }
        }
      }
    `,
    organization
  )

  const classes = useStyles()
  const canReorder = activeOrganization.viewerPermissions.has("apps.manage")

  if (!data) return null

  const navSections = Relay.connectionToArray(data.communityNavSections)

  return (
    <AppLevelProvider>
      <ActiveAppModalProvider>
        <DiscoSideBar
          fullHeight
          data-testid={COMMUNITY_SIDE_BAR_TEST_ID}
          {...props}
          header={<CommunitySidebarHeader />}
          items={
            <div className={classes.items}>
              <AppsSidebarDragDropProvider>
                <div
                  className={classes.defaultApps}
                  data-testid={"CommunitySideBar.defaultApps"}
                >
                  {/* Render sidebar items for organization apps */}
                  {canManage &&
                    activeOrganization?.forYouDashboard?.published === false && (
                      <CommunitySidebarItem
                        testid={`${COMMUNITY_SIDE_BAR_TEST_ID}.ForYou`}
                        leftIcon={
                          <Badge
                            isSideBar={true}
                            isSelected={
                              location.pathname === ROUTE_NAMES.COMMUNITY.HOME.FOR_YOU
                            }
                            badgeKey={null}
                            badgeData={{
                              kind: "icon",
                              icon: "home-stars",
                              color: "#ffffff00",
                            }}
                            className={classes.homeIcon}
                          />
                        }
                        to={ROUTE_NAMES.COMMUNITY.HOME.FOR_YOU}
                        name={"For You"}
                        rightContent={<DiscoChip color={"yellow"} label={"Draft"} />}
                      />
                    )}

                  <CommunitySidebarItem
                    testid={`${COMMUNITY_SIDE_BAR_TEST_ID}.Home`}
                    leftIcon={
                      <Badge
                        isSideBar={true}
                        isSelected={location.pathname === ROUTE_NAMES.COMMUNITY.HOME.ROOT}
                        badgeKey={null}
                        badgeData={{
                          kind: "icon",
                          icon: activeOrganization.forYouDashboard?.published
                            ? "home-stars"
                            : "house",
                          color: "#ffffff00",
                        }}
                        className={classes.homeIcon}
                      />
                    }
                    to={ROUTE_NAMES.COMMUNITY.HOME.ROOT}
                    name={
                      activeOrganization.forYouDashboard?.published ? "For You" : "Home"
                    }
                  />
                  <AppsSidebarList
                    organizationKey={organization}
                    testid={`${COMMUNITY_SIDE_BAR_TEST_ID}.top-level-apps`}
                  />
                </div>

                {/* Render navigation sections in the sidebar */}
                <AppsSidebarNavSectionsDragDrop
                  navSections={navSections}
                  disabled={!canReorder}
                >
                  {(section, dragHandleProps, isDragging, i) => (
                    <Fragment key={section.id}>
                      <NavSection
                        navSectionKey={section}
                        index={i}
                        isDragging={isDragging}
                        dragHandleProps={dragHandleProps}
                        defaultCollapsed={navSections.length > 10}
                      />
                      {i === navSections.length - 1 && hasNext && (
                        // Putting this outside the DragDrop caused it to get stuck at the
                        // bottom and continuously load more non-stop
                        <DiscoScrolledIntoView
                          onScrolledIntoView={() => loadNext(NAV_SECTIONS_PER_PAGE)}
                          isLoading={isLoadingNext}
                          skeleton={
                            <>
                              {range(7).map((k) => (
                                <NavSectionSkeleton key={k} />
                              ))}
                            </>
                          }
                        />
                      )}
                    </Fragment>
                  )}
                </AppsSidebarNavSectionsDragDrop>

                {canReorder && (
                  <CreateNavSectionButton navSectionIndex={navSections.length}>
                    {(btnProps) => (
                      <DiscoDividerButton
                        testid={btnProps.testid}
                        tooltip={"Add Section"}
                        marginTop={0}
                        marginBottom={0}
                        marginLeft={0}
                        marginRight={0}
                        showOnHover
                        borderWidth={"1px"}
                        onClick={btnProps.onClick}
                      />
                    )}
                  </CreateNavSectionButton>
                )}
              </AppsSidebarDragDropProvider>
            </div>
          }
        />
      </ActiveAppModalProvider>
    </AppLevelProvider>
  )
}

const useStyles = makeUseStyles((theme) => ({
  defaultApps: {
    padding: theme.spacing(2, 2, 0),
  },
  items: {
    paddingBottom: theme.spacing(2),
  },
  communitySideBarSkeleton: {
    display: "grid",
    gap: 1,
    padding: theme.spacing(2),
  },
  homeIcon: {
    width: "30px",
    height: "30px",
  },
}))

export function DiscoSideBarSkeleton(props: DiscoSidebarHeaderProps) {
  const { icon, title } = props
  const isMobile = useIsMobile()
  const classes = useStyles()
  if (isMobile) return null

  return (
    <DiscoSideBar
      fullHeight
      header={<DiscoSidebarHeader title={title} icon={icon} />}
      items={
        <div className={classes.communitySideBarSkeleton}>
          {range(4).map((i) => (
            <DiscoSideBarItemSkeleton key={i} />
          ))}
          {range(2).map((i) => (
            <Fragment key={i}>
              <DiscoDivider />
              <DiscoTextSkeleton width={"100px"} />
              <DiscoSideBarItemSkeleton />
              <DiscoSideBarItemSkeleton />
            </Fragment>
          ))}
        </div>
      }
    />
  )
}

function CommunitySideBarSkeleton() {
  const sidebarCommunity = useLabel("sidebar_community")
  return <DiscoSideBarSkeleton title={sidebarCommunity.singular} icon={"home-alt"} />
}

export default Relay.withSkeleton({
  component: CommunitySideBar,
  skeleton: CommunitySideBarSkeleton,
})
