import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  EventsJumpToDateCalendarQuery,
  EventsJumpToDateCalendarQuery$variables,
} from "@/occurrence/sidebar/__generated__/EventsJumpToDateCalendarQuery.graphql"
import {
  EventsCalendarQueryParams,
  getEventFilters,
} from "@/organization/occurrence/OrganizationOccurrenceList"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton } from "@disco-ui"
import DiscoCalendar from "@disco-ui/date/DiscoCalendar"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { TestIDProps } from "@utils/typeUtils"
import { endOfMonth, startOfMonth } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { useEffect, useRef, useState } from "react"
import { useSubscribeToInvalidationState } from "react-relay"
import { graphql } from "relay-runtime"

type EventsJumpToDateCalendarProps = TestIDProps

function EventsJumpToDateCalendar({
  testid = "EventsCalendar",
}: EventsJumpToDateCalendarProps) {
  const classes = useStyles()
  const timezone = useUserTimezone()

  const defaultDate = useRef(new Date())

  const activeOrganization = useActiveOrganization()!

  const [params, setParams] = useQueryParamState<EventsCalendarQueryParams>()

  const selectedDate = getSelectedDate()
  const selectedTimestamp = selectedDate.getTime()
  const [activeMonth, setActiveMonth] = useState(selectedDate)

  const productIds = params.productIds?.split(",") || []

  useEffect(() => {
    if (activeMonth.getTime() === selectedDate.getTime()) return
    setActiveMonth(selectedDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimestamp])

  const filters = getEventFilters({
    filter: params.calendarTab,
    dateFilter: params.dateFilter,
    productIds,
  })

  const startDate = startOfMonth(activeMonth)
  const endDate = endOfMonth(activeMonth)

  const queryVariables: EventsJumpToDateCalendarQuery$variables = {
    id: activeOrganization.id,
    startsAfter: startDate.toISOString(),
    startsBefore: endDate.toISOString(),
    datetimeFilter: filters.datetimeFilter,
    excludeCommunityEvents: filters.excludeCommunityEvents,
    includedProductIds: filters.includedProductIds,
    hasRecording: filters.hasRecording,
  }

  const [{ organization }, refetch] =
    Relay.useRefetchableBackgroundQuery<EventsJumpToDateCalendarQuery>(
      graphql`
        query EventsJumpToDateCalendarQuery(
          $id: ID!
          $startsAfter: DateTime!
          $startsBefore: DateTime!
          $datetimeFilter: OrganizationOccurrencesDatetimeFilter
          $excludeCommunityEvents: Boolean
          $includedProductIds: [ID!]
          $hasRecording: Boolean
        ) {
          organization: node(id: $id) {
            ... on Organization {
              occurrences(
                first: 50 # There should never be more than 31
                startsBefore: $startsBefore
                startsAfter: $startsAfter
                datetimeFilter: $datetimeFilter
                excludeCommunityEvents: $excludeCommunityEvents
                includedProductIds: $includedProductIds
                hasRecording: $hasRecording
                onlyDistinctDates: true
              ) @connection(key: "EventsJumpToDateCalendar__occurrences") {
                __id
                totalCount
                edges {
                  node {
                    id
                    datetimeRange
                  }
                }
                pageInfo {
                  startCursor
                  endCursor
                  hasNextPage
                  hasPreviousPage
                }
              }
            }
          }
        }
      `,
      queryVariables,
      { fetchPolicy: "store-or-network" }
    )

  const occurrences = Relay.connectionToArray(organization?.occurrences)

  // Listen for invalidation in CreateEventForm
  useSubscribeToInvalidationState([organization?.occurrences?.__id ?? ""], () => {
    if (!organization?.occurrences) return
    refetch(queryVariables)
  })

  const occupiedDays = new Set<string>()

  for (const occurrence of occurrences) {
    const start = new Date(occurrence.datetimeRange[0])
    occupiedDays.add(encodeDate(start))
  }

  return (
    <div className={classes.calendarContainer}>
      <DiscoCalendar
        key={selectedTimestamp}
        testid={`${testid}.Calendar`}
        mode={"single"}
        defaultMonth={selectedDate}
        onMonthChange={setActiveMonth}
        selected={selectedDate}
        onSelect={handleDateSelect}
        occupiedDays={(date: Date) => {
          return occupiedDays.has(encodeDate(date))
        }}
        compact
      />
      <DiscoButton
        testid={`${testid}.jump-to-today`}
        color={"grey"}
        variant={"outlined"}
        onClick={() => {
          const today = new Date()
          today.setHours(0, 0, 0)
          const timestamp = today.getTime().toString()
          setParams({ jumpDate: timestamp, date: timestamp }, "replace")
        }}
      >
        {"Jump to Today"}
      </DiscoButton>
    </div>
  )

  function getSelectedDate() {
    if (params.date) return new Date(Number(params.date))
    return defaultDate.current
  }

  function handleDateSelect(newDate: Date | undefined) {
    if (!newDate) return

    // Update the milliseconds to ensure you can select the same date multiple times
    const now = new Date()
    newDate.setMilliseconds(now.getMilliseconds())

    const newDateTime = newDate.getTime().toString()
    setParams({ date: newDateTime, jumpDate: newDateTime }, "replace")
  }

  function encodeDate(date: Date) {
    return formatInTimeZone(date, timezone, DATE_FORMAT.DEFAULT)
  }
}

const useStyles = makeUseStyles((theme) => ({
  calendarContainer: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.measure.borderRadius.medium,
    display: "grid",
    gap: theme.spacing(1),
    margin: theme.spacing(1.5, 2),
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
}))

export default EventsJumpToDateCalendar
