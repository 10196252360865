import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import CalendarIcon from "@/core/ui/iconsax/linear/calendar.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import RSVPToAllOrganizationEventsButton from "@/occurrence/button/rsvp-to-all-button/RSVPToAllOrganizationEventsButton"
import { CommunityEventsCalendarPageQuery } from "@/organization/events-calendar/__generated__/CommunityEventsCalendarPageQuery.graphql"
import CreateEventButton from "@/organization/occurrence/create-form/CreateEventButton"
import OrganizationOccurrenceList, {
  EventsCalendarQueryParams,
  EVENTS_FILTER_OPTIONS,
  getEventFilters,
} from "@/organization/occurrence/OrganizationOccurrenceList"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoButton, DiscoDropdown } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import DiscoPage from "@disco-ui/page/DiscoPage"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { capitalize, Grid } from "@material-ui/core"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { useEffect, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { useParams } from "react-router"
import { graphql } from "relay-runtime"

export interface EventsCalendarPageParams {
  appId?: GlobalID
}

type Props = {
  isCommunityEventsApp?: boolean
}

export default function CommunityEventsCalendarPage({ isCommunityEventsApp }: Props) {
  const testid = "CommunityEventsCalendarPage"
  const activeOrganization = useActiveOrganization()!
  const [params, setParams] = useQueryParamState<EventsCalendarQueryParams>()
  const { appId } = useParams<EventsCalendarPageParams>()
  const classes = useStyles()
  const canCreate = activeOrganization.viewerPermissions.has("events.create")

  const { authUser } = useAuthUser({ required: true })
  const profileDrawer = useGlobalDrawer("profileSettings")
  const { outlookCalendarConnection } = useFeatureFlags()

  const [anchorDate, setAnchorDate] = useState<Date>(new Date())

  useEffect(() => {
    if (!params.jumpDate) return
    setAnchorDate(new Date(Number(params.jumpDate)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.date])

  const filter = params.calendarTab || (isCommunityEventsApp ? "upcoming" : "all")

  const { datetimeFilter, hasRecording } = getEventFilters({
    filter,
    dateFilter: params.dateFilter,
  })

  useEffect(() => {
    // Reset anchor date when filters change
    setAnchorDate(new Date())
  }, [params.calendarTab, params.dateFilter, params.productIds])

  // Hide the "Attend All" button if on a community events app or the user is viewing past events
  const hideRSVPToAllButton =
    isCommunityEventsApp || datetimeFilter === "past" || hasRecording

  const { app } = useLazyLoadQuery<CommunityEventsCalendarPageQuery>(
    graphql`
      query CommunityEventsCalendarPageQuery($id: ID!) {
        app: node(id: $id) {
          ... on ProductApp {
            id
            customAppTitle
            badge {
              icon
            }
          }
        }
      }
    `,
    {
      id: appId || "",
    }
  )

  return (
    <DiscoPage
      HeaderProps={{
        title: getTitle(),
        leftIcon: getIcon(),
        rightOptions: (
          <Grid container className={classes.rightButtonsContainer}>
            {!hideRSVPToAllButton && (
              <RSVPToAllOrganizationEventsButton filter={filter} showWhenDisabled>
                {(buttonProps) => (
                  <DiscoButton
                    {...buttonProps}
                    leftIcon={<CalendarIcon />}
                    color={canCreate ? "grey" : "primary"}
                    variant={canCreate ? "outlined" : "contained"}
                    testid={`${testid}.RSVPToAllOrganizationEventsButton`}
                    onlyIconInMobile
                  >
                    {"Attend All"}
                  </DiscoButton>
                )}
              </RSVPToAllOrganizationEventsButton>
            )}

            {canCreate && (
              <CreateEventButton
                testid={testid}
                onlyIconInMobile
                onCreate={(occurrence) => {
                  if (datetimeFilter === "past" || hasRecording) return
                  // If viewing upcoming events, jump to the newly created event
                  // To avoid jumping to a random date when the list is re-rendered
                  const eventDate = new Date(occurrence.datetimeRange[0])
                  eventDate.setHours(0, 0, 0, 0)
                  const jumpDate = eventDate.getTime().toString()
                  setParams({ date: jumpDate, jumpDate }, "replace")
                }}
              />
            )}

            {isCommunityEventsApp && (
              <DiscoDropdown
                menuButton={(buttonProps) => (
                  <DiscoButton
                    {...buttonProps}
                    testid={`${testid}.filter-dropdown-button`}
                  >
                    {capitalize(params.calendarTab || "upcoming")}
                  </DiscoButton>
                )}
              >
                {EVENTS_FILTER_OPTIONS.map((item) => (
                  <DiscoDropdownItem
                    key={item.value}
                    selected={params.calendarTab === item.value}
                    title={item.title}
                    testid={`${testid}.filter-item.${item.title}`}
                    onClick={() => setParams({ calendarTab: item.value })}
                  />
                ))}
              </DiscoDropdown>
            )}

            {outlookCalendarConnection && (
              <DiscoMoreActionsDropdown
                testid={"CalendarConnectionDropdown"}
                moreActionsButtonClassName={classes.moreActionsButton}
                menuButtonTooltipProps={{
                  placement: "bottom",
                }}
              >
                <DiscoDropdownItem
                  title={`${
                    authUser.calendarConnection ? "Disconnect" : "Connect"
                  } Outlook Calendar`}
                  onClick={() => {
                    profileDrawer.open({
                      drawerProfileId: authUser.id,
                      profileSettingsTab: "connections",
                    })
                  }}
                />
              </DiscoMoreActionsDropdown>
            )}
          </Grid>
        ),
      }}
    >
      <OrganizationOccurrenceList
        // Re-render the list if we jump to a new date or filter
        key={`${anchorDate.getTime()}${filter}${params.dateFilter}${params.productIds}`}
        filter={filter}
        excludeProductEvents={isCommunityEventsApp}
        appId={appId}
        anchorDate={anchorDate}
      />
    </DiscoPage>
  )

  function getTitle() {
    if (app?.customAppTitle) return app.customAppTitle

    switch (params.calendarTab) {
      case "recordings":
        return "Recordings"
      case "past":
        return "Past Events"
      case "upcoming":
        return "Upcoming Events"
      default:
        return "All Events"
    }
  }

  function getIcon() {
    if (app?.badge?.icon) return app.badge.icon

    switch (params.calendarTab) {
      case "recordings":
        return "video-square"
      case "past":
        return "story"
      case "upcoming":
        return "timer"
      default:
        return "ticket"
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  rightButtonsContainer: {
    gap: theme.spacing(1),
    flexBasis: "content",
  },
  moreActionsButton: {
    transform: "rotate(90deg)",
    height: 40,
    width: 40,
  },
}))
