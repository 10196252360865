/**
 * @generated SignedSource<<37135a7b66734c4790c9c6e619e496da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PathwaySidebarItemFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly status: ProductStatus;
  readonly type: ProductType;
  readonly viewerMembership: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"LeaveProductButtonFragment" | "ProductBadgeFragment" | "usePathwayAdminDropdownItemsFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "PathwaySidebarItemFragment";
};
export type PathwaySidebarItemFragment$key = {
  readonly " $data"?: PathwaySidebarItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PathwaySidebarItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PathwaySidebarItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LeaveProductButtonFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductBadgeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePathwayAdminDropdownItemsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "3246e612b75ef5f3a4e8c6fac3c2401c";

export default node;
